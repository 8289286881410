<template>
  <v-container fluid class="py-0">
    <heading />
    <payments-table />
  </v-container>
</template>

<script>
import Heading from "../components/Heading";
import PaymentsTable from "./Table";

export default {
  mixins: [],
  components: {
    Heading,
    PaymentsTable,
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created: async function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
